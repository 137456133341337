import React, { useState, useEffect, useContext } from 'react'
import { CheckCircleFill, ExclamationCircleFill } from 'react-bootstrap-icons';
import { Card, Container, Row, Col, Alert, Form, FormGroup, InputGroup, Button } from 'react-bootstrap'
import AuthContext from '../../../context/AuthProvider'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


//pwd regular expression
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W|_]).{8,}$/


export default function ChangePassword() {
    const navigate = useNavigate()
    const {auth, setAuth} = useContext(AuthContext)
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [errMsg, setErrMsg] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const [matchNewPwd, setMatchPwd] = useState('')
    const [pwdMatch, setPwdMatch] = useState(false)
    const [validPwd, setValidPwd] = useState(false)
    const [registrationType, setRegistrationType] = useState('')
    const [userEmail, setUserEmail] = useState( sessionStorage.getItem("email"))
    
    useEffect(()=> {
        didMount(true)
        setRegistrationType( sessionStorage?.getItem('registrationType') )

        if ( sessionStorage?.getItem('registrationType') != 'Password change' ) {
            alert('Error ')
        }
    }, [])

    useEffect(()=> {
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])
    
    
    useEffect(()=> {
        if (newPwd != '') {
            if (!PWD_REGEX.test(newPwd)) {
                setErrMsg("Password is not valid")
                setValidPwd(false)
            } else {
                setErrMsg("")
                // if password is valid, check if they match
                setValidPwd(true)

                if (matchNewPwd != '') {
                    if (newPwd == matchNewPwd) {
                        setPwdMatch(true)
                        setErrMsg('')
                    } else {
                        //pwds do not match
                        setPwdMatch(false)
                        setErrMsg("Passwords do not match")
                    }
                } else {
                    //pwd match field empty, therefore pwds do not match
                    setPwdMatch(false)
                    setErrMsg("Passwords do not match")
                }
            }
        } else {
            setErrMsg("")
        }

    }, [newPwd, matchNewPwd])



    const onSubmit = async (e) => {
        e.preventDefault()

        //1. if valid pwd
        if (validPwd && pwdMatch) {
            //2. api post to back and change pwd
           await axios.post("/user/change-password", { newPassword: newPwd  , userEmail })
                .then ((res)=> {
                    const data = res.data;
                    if (data.success == true ) {
                        // succussfully changed password
                        console.log("Successfully changed user password")

                        // start user login session
                        
                        sessionStorage.removeItem("registrationType")
                        sessionStorage.setItem("passwordChanged", true)
                        //navigate to login page
                        navigate('/login?passwordChanged=true', { replace: true })

                    } else {
                        setErrMsg(res.data.message)
                    }
                }).catch( (error) => {
                    setErrMsg("We apologize for the inconvenience, but an error has occurred on our end. Please attempt to reset your password at a later time.")
                })

        } else {
            setErrMsg("Password is not valid.")
        }
    }
    if (!loading) return (
        <Container>
            <Row className='mt-100'>
                <Col></Col>
                <Col sm={10} md={6}>
                    <Alert variant='danger' className={ errMsg == ''? ("hide"):("show")} >
                        { errMsg }
                    </Alert>

                        <Card>
                            <Card.Header> Reset Password</Card.Header>

                            <Card.Body>
                                <Form onSubmit={onSubmit} >

                                    <Alert variant='primary'>
                                        Welcome to CSAM's new website! To access your previous account, you will need to create a new password.
                                    </Alert>

                                    <FormGroup>
                                        <Form.Label> Email </Form.Label>
                                        <Form.Control 
                                        type="email"
                                        placeholder={ userEmail }
                                        disabled/>
                                    </FormGroup>


                                    <FormGroup>
                                        <Form.Label> New Password*  </Form.Label>
                                        <Form.Control 
                                            type="password"
                                            value={newPwd}
                                            onChange={e=> setNewPwd(e.target.value)}
                                        />
                                    </FormGroup>

                                    <FormGroup className='mb-3'>
                                        <Form.Label> Re-type Password* </Form.Label>
                                        <Form.Control 
                                            type="password"
                                            value={matchNewPwd}
                                            onChange={e=> setMatchPwd(e.target.value)}
                                        />
                                    </FormGroup>

                                    <Alert variant="warning" className= { !validPwd? ("show"): ("hide")}>
                                            <small>
                                                <ul>
                                                    <li>The password must be at least 8 characters long</li>
                                                    <li> The password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.</li>
                                                </ul>
                                            </small>
                                    </Alert>

                                    <div className='mt-3'>

                                        <Button type="sumbit" disabled = {!validPwd || !pwdMatch? true: false} className="mr-2"> 
                                            Reset
                                        </Button>
                                    </div>

                                    
                                </Form>
                            </Card.Body>
                        </Card>

                </Col>
                <Col></Col>
            </Row>
        </Container>

    )
}