import React, { useContext, useState, useEffect } from 'react';
import { Card, Col, Container, Row, Image, ListGroup } from 'react-bootstrap';
import MembershipTable from './membershiptable.component';
import AuthContext from '../../../../context/AuthProvider';
import { Gear, GearFill, GraphUpArrow, House, People, Person, PersonAdd, PersonBadge, PersonCircle } from 'react-bootstrap-icons';
import Analytics from './analytics.component';
import { useNavigate } from 'react-router-dom';
import { getURLParams } from '../../../../tools/main';
import { AdminMenu } from './sidemenu';




export default function AdminView() {
    const {auth, setAuth} = useContext(AuthContext)
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [currentView, setView] = useState('manage-members')
    useEffect(()=> {
        didMount(true)

    }, [])
    useEffect(()=> {
        if (isMounted) isLoading(false)
    }, [auth, isMounted])

    useEffect(()=> {
        console.log(currentView)
    },[currentView])

    if (!loading) return (
        <Container className='' style={{ 
                position: 'absolute',
                height: "100%", 
                top: "26px",
                bottom: "0px"}}>
            
            <Row className='h100 mt-5 mb-90' style={{ height: "100%" }}>
                <Col xs={2} sm={2} className='mb-3 px-0' style={{ backgroundColor: "#f8f8f8" }}>
                    < AdminMenu currentView = { currentView }  setView={ setView } />
                </Col>
                { currentView == 'analytics'  && ( < Analytics />)}
                { currentView == 'manage-members'  && ( < MembershipTable />)}
                {/* <Col sm={1} className='mb-3'></Col> */}
            </Row>
        </Container>
        )
}

