import { Form, InputGroup, Button } from 'react-bootstrap';
import { useState } from 'react';
import { Search } from 'react-bootstrap-icons';

export default function AdminSearchBar({ onSearch }) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSearch) {
            onSearch(searchTerm);
        }
    };

    return (
        <Form onSubmit={handleSubmit} className='search-bar'>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    aria-label="Search"
                />
                <Button variant="outline-secondary" type="submit">
                    <Search />
                </Button>
            </InputGroup>
        </Form>
    )
}