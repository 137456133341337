import React, { useState, useEffect } from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';
import { EnvelopeAtFill, Instagram, Linkedin, Mailbox, Messenger } from 'react-bootstrap-icons';

const CSAM_EMAIL = "csam.scma@gmail.com"
export default function ContactUs() {
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    useEffect(()=>{
        didMount(true)
    }, [])

    useEffect(()=>{
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])

    if (!loading) return(
        <Container>
            <Row className='h100'>
                <Col></Col>
                <Col xs={8} sm={4} >
                    <Card className='vertical-center'>
                        <Card.Header style={{ fontWeight: "600"}}> Connect with us </Card.Header>
                        <Card.Body style={{ textAlign: "center"}} >
                            <p> Email Administrator: <EnvelopeAtFill className='mx-1' style ={{cursor: "pointer"}} onClick={ e=> window.open("mailto:" + CSAM_EMAIL) } /> </p>
                            <p> LinkedIn: <Linkedin className='mx-1' style ={{cursor: "pointer"}} onClick={ e=> window.open("https://www.linkedin.com/company/canadian-society-for-aerospace-medicine-student-resident-section-cstars/mycompany/")} /> </p>
                            <p> Instagram: <Instagram className="mx-1" style ={{cursor: "pointer"}} onClick={e=> window.open("https://www.instagram.com/csam_scma/?hl=en") } /> </p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}