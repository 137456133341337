import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import { Card, Col, Container, Row, Form, Button, Alert } from 'react-bootstrap';
import { Key } from 'react-bootstrap-icons'
import { useNavigate, redirect } from 'react-router-dom';
import AuthContext from '../../../context/AuthProvider';


const VALIDATE_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const LOGIN_URL = 'http://localhost:8000/user/login'

function DisplayReasonForVerification () {
    const registrationType = sessionStorage.getItem('registrationType')
    
    return (
        <div>
            <Alert variant='warning' className= { registrationType == 'Password change'? ('show'):('hide')}>
                Before reseting your password, we must first verify your email. 
                Please click the button below to receive a one-time password (OTP). 
                This OTP will be valid for one hour. 
                After receiving it, you can enter the passcode below to verify your 
                email address.
            </Alert>

            <Alert variant='warning' className= { registrationType == 'newUser'? ('show'):('hide')}>
                To activate your account, we need to verify your email address. 
                Please click the button below to receive a one-time password (OTP). 
                This OTP will be valid for one hour. After receiving it, 
                you can enter the passcode below to verify your email address.
            </Alert>
        </div>
    )
}
export default function VerifyAccount() {
    const navigate = useNavigate()

    const {auth, setAuth} = useContext(AuthContext)
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    

    const [userOTP, setUserOTP] = useState()
    const [codeSent, setCodeSent] = useState(false)
    const [email, setEmail] = useState(sessionStorage.getItem("email"))
    const [otpVerified, setOtpVerified ] = useState(false)
    const [resetType, setResetType] = useState('')
    const [registrationType, setRegistrationType] = useState('')
    const [showVerifyBtn, disableVerifyBtn] = useState(true)
    const [errMsg, setErrMsg] = useState('')

    useEffect(()=> {
        
        didMount(true)
        if ( sessionStorage.getItem("registrationType") == null || sessionStorage.getItem("email") == null ) {
                navigate('/login')
        } else {
            setRegistrationType(sessionStorage.getItem("registrationType"))
        }
    },[])

    useEffect(()=> {

        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])

    //sents post request to db to check if user credentials are valid
    const getUserCredentials = async (user, pwd) => {

        try {
            

            const response = await axios.post(LOGIN_URL, {
                    userKey: "email",
                    user: user.toLowerCase(),
                    password: pwd
                })
            
            
            // console.log(response)
            
            // if user was found, return user info
            if (response.data.success) {    
                return response
            
            } else {
                setErrMsg(response.data.message)
                return null
            }    
        } catch(err) {
            setErrMsg("Sorry! Our servers are currently down. We are currently trying fix this issue, please comeback at a later time.")
            // console.log("ERROR! unable able to search for user")
            return false
           }
    }
    
    const sendOTP = async (e) => {
        e.preventDefault()
        disableVerifyBtn(false)
        await axios.post("/user/send-otp", { email: email.toLowerCase(), registrationType})
        setCodeSent(true)

    }
    const onSubmit = async (e) => {
        e.preventDefault()

        //send post request to back end to see if OTP is valid
        const result = await axios.post("/user/verify-otp", { email, otp: userOTP, time: Date.now(), registrationType } )
            .then((res) => {
                return res
            }).catch ((error)=> {
                console.log(error)
                if(error.response.data.message == "Server Not Able to Verify OTP at the moment") {
                    setErrMsg("Sorry, we are not able to verify your account at the moment. Please try again at a later time.")
                } else {
                    setErrMsg("Unexpected Internal Error. Please try again at a later time.")
                }
                
                return error.response
            })
        // check if otp inputed is valid (both code and within time expirartion), redirect to login page
        if (result.data.validOTP) {
            //set storage to email for next "/change-password" page to use. Deleate there
            sessionStorage.setItem("email", email)
            //copy properties of auth
            let authHolder= {...auth}
            //update to add new property to auth holder
            authHolder.email = email ;
            setAuth ({...authHolder})

            //show account verified meesage, w/ link to redirect user
            setRegistrationType(result.data.registrationType)
            console.log(result.data.registrationType)
            setOtpVerified(true)
            // clear error message
            if (errMsg == 'OTP not valid.') {
                setErrMsg('')
            }
            
        } else {
            //OTP is not valid
            setErrMsg(result.data.message)
            setOtpVerified(false)
        }
        
    }
    const verificationType = () => {

        if (registrationType == 'Password change') {
            return 'Reset Password'
        } else {
            return 'Account Verification'
        }
    }
    useEffect(()=>{
        if (otpVerified) {
            console.log(registrationType)
            if (registrationType == 'Password change') {
                navigate('/change-password', {replace: true})
            } else {
                sessionStorage.setItem('registrationType', 'newUser-verified')
                navigate('/login', { replace: true })
            }
        }
    },[otpVerified, registrationType])


    const otpFormView = () => {

        return(
            <Card>
            <Card.Header> { verificationType() }  </Card.Header>
                <Card.Body>

                <DisplayReasonForVerification />

                <Form onSubmit={onSubmit}>

                    <Form.Group>
                        <Form.Label> Email </Form.Label>
                        <Form.Control 
                        type="text"
                        placeHolder= {sessionStorage.getItem("email")}
                        disabled
                        readOnly
                         />
                        
                    </Form.Group>

                    <Button 
                        className='mt-3' 
                        onClick={ e => sendOTP(e)}> 
                        Send One Time Password (OTP) 
                    </Button>
                    <br/>
                        { codeSent ? (<small> 
                            We're sending your code now. Please wait up to 15 minutes and check your email, <strong>including spam/junk</strong>, for the verification link.
                        </small>) : (<></>) }

                    <Form.Group className="show mt-3">
                        <Form.Label style={{ fontWeight: "bold" }}> Enter OTP </Form.Label>
                        <Form.Control 
                        type="text"
                        value={userOTP}
                        onChange={e=>setUserOTP(e.target.value)}
                        placeholder=""/>
                        
                    </Form.Group>

                    <small>
                        Don't have an account? <a href="/register"> Register </a>
                    </small>
                    
                    <br/>

                    <Button 
                        className='mt-2 verify-btn' 
                        style={{ float: "right", color: 'white'}} 
                        type="submit"
                        disabled={showVerifyBtn}>
                        Verify OTP
                    </Button>
                </Form>
            </Card.Body>
        </Card>
        )
    }

    const successVerifiedView = () => {
        return (
            <Card>
                <Card.Body>
                    Congratulations! 
                    Your account has been successfully verified, 
                    enhancing its security. 
                    Please <a href='' onClick={e=> {navigate('/login', {replace: true} )}}> log in </a>to access your account.
                </Card.Body>
            </Card>
        )
    }


    if (!loading) return (
        (<Container>
            <Row className='mt-100 mb-90' style={{}}>
                <Col></Col>
                <Col xs={10} sm={9} md={5}>
                    <Alert variant='danger' className={ errMsg == ''? ("hide"):("show")} >
                        { errMsg }
                    </Alert>
                    { !otpVerified ? ( otpFormView() ):( successVerifiedView()) }
                </Col>
                <Col></Col>
            </Row>
        </Container>)
    )
}