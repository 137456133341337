import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";



const data = {
    title: '“Red Seal” and CSAM Student Travel Awards',
    background: "The Canadian Society of Aerospace Medicine (CSAM) is pleased to offer three travel awards to support Canadian learners in a healthcare field to attend a conference, educational or research experience relevant to the field of Aerospace Medicine.",
    value: "$1000 per recipient.",
    eligbility: [
        "Be a member of CStaRS. ",
        "Enrolled as a Canadian healthcare program student or resident at the time of application and anticipated travel.",
        "Submit a short letter (~500 words) explaining interest in Aerospace Medicine and how the educational experience will further their learning. ",
        "Specify the name and timing of the conference or educational/research opportunity, estimated costs, and presentation details (if applicable). ",
        "Priority will be given to those who have not been previous recipients of this award. "
      ],
    selection_criteria: "A selection committee will review all applicant submissions. Travel awards will be decided based on quality of the submission, relevance to Aerospace Medicine, and the applicant’s career aspirations. Examples of eligible conferences and experiences include but are not limited to: Aerospace Medical Annual Meeting, International Conference of Aerospace Medicine (ICAM), International Space University, etc. ",
    apply: 'To apply, please send your application to: csam.scma@gmail.com by January 31, 2025 at 11:59pm. Successful applicants will be contacted within 1 month, as well as announced in at the annual CSAM breakfast at AsMA.',
    deadline: 'January 31, 2025 at 11:59pm.',
    contact: "csam.scma@gmail.com"

}

export default function Awards(){
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    useEffect (()=>{
        didMount(true)
    },[])

    useEffect(()=> {
        if (isMounted) isLoading(false)
    }, [isMounted])
    
    if (!loading) return (
        <Container fluid className="mx-auto">
            <Row className="row-stn mx-auto mt-50" style={{ width: '100%'}} >
                <Col></Col>
                <Col xs={10} sm={8}>
                    <Card>
                        <Card.Body className="award-card-body" >
                            <h4 style={{ textAlign: "center"}}> {data.title} </h4>

                            <br/>

                            <h7> Deadline: <span> {data.deadline} </span></h7>
                            <br/>
                            <h7> Award value: <span> {data.value} </span></h7>
                            {/* <h7> Contact <span> {data.contact} </span></h7> */}

                            <br/>
                            <br/>

                            <h7> Background </h7>
                            <p> {data.background }</p>
                            <br/>

                            <h7> Eligbility </h7>
                            <p> {data.eligbility }</p>
                            <br/>

                            <h7> Selection criteria </h7>
                            <p> {data.selection_criteria}</p>
                            <br/>

                            <h7> How to apply ? </h7>
                            <p> {data.apply}</p>

                        </Card.Body>
                    </Card>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}