import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, Modal, Image, ListGroup, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function HeroComponent() {
    const navigate = useNavigate()
    return (
        <>
            <Row id="home-wrapper" >
                <div id="home-wrapper-col" className="overlay"> 
                    <Row className='h100'>
                        <Col></Col>
                        <Col className='h100' style={{ textAlign: "center"}} xs={10} sm={6} >
                            {/* { homeSlides() } */}
                                <div className='home-banner-container fade-in' >
                                    <div className='home-banner-text'>
                                        <h1 style={{ fontWeight: "700"}}>The Canadian Society of Aerospace Medicine (CSAM) </h1>
                                        <h4 style={{ textAlign: "center", fontFamily: "Times New Roman" }}>
                                            The official organization representing aerospace medicine professionals in Canada.
                                        </h4>

                                        
                                            <Button variant="outline-warning" className='home-btn shadow-lg' onClick={ (e)=> navigate("/about") } >
                                                ABOUT US
                                            </Button>

                                            <Button  variant="outline-warning"  className='home-btn shadow-lg' onClick={ (e)=> navigate("/membership") }>
                                                MEMBERSHIP
                                            </Button>
                                        
                                    </div>

                                </div>            
                        </Col>
                        <Col></Col>
                    </Row>
                </div>
            </Row>
            
        </>
    )

}