
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Image, ListGroup, Carousel } from 'react-bootstrap';
import { ArrowLeft, ArrowLeftCircle, ArrowRight, ArrowRightCircle, ChevronBarLeft, ChevronBarRight, ChevronLeft, ChevronRight, Clock } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

export function EventsCard({event, index, startIndex}) {
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [tracker, setTracker] = useState(startIndex)

    useEffect(()=> {
        console.log(event)
    },[])
    useEffect(()=> {
        didMount(true)
        console.log(startIndex)
    },[startIndex])


    useEffect(()=> {
        if (isMounted) isLoading(false)
    },[isMounted])

    return (
            <Card className= "nasa-style-card" key={index}>
                <div className='card-image-container'>
                    {event.poster_url ? 
                        <Card.Img className='' alt='' variant="top" src={window.location.origin + '/event/images/' + event?.poster_url} /> 
                            : <Skeleton className="event-image" />
                    }
                </div>
                <Card.Body style={{ backgroundColor: "red!important", padding: "1rem 0.3rem 0 0.3rem" }}>
                    
                
                <Card.Text className="card-date"> {event.date ? <> <Clock /> {event.date} </> : <Skeleton  count={1}/> }</Card.Text>

                {   event.title ? 
                    <Card.Title className='event-title'>
                        {event.title}
                    </Card.Title>: <Skeleton count = {1} />
                }
                <Card.Text className='card-excerpt event-text'>
                    { event.description ? event.description : <Skeleton count = {4}/>    }
                </Card.Text>  
                    <a href={'/event-view/' + event?.poster_url} target="_blank" rel="noopener noreferrer" className="read-more-link">
                        Read More <ArrowRight size={14} />
                    </a>
                </Card.Body>
            </Card>
    )
}
export function ShowEvents({events}) {
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [startIndex, setStartIndex] = useState(0)
    const navigate = useNavigate()
    
    useEffect(()=> {
        didMount(true)
    },[])
    useEffect(()=> {
        if (isMounted) {
            // console.log(events)
            isLoading(false)
        }
    },[isMounted])


    return (
            <div className="nasa-featured-news">
                {/* Header Section */}
                <div className="section-header">
                    <h2> Events </h2>
                    <a href="/events" className="view-all-link"> More CSAM events </a> 
                </div>

                {/* Events Cards Section */}
                <div className="news-grid">
                    {[0, 1, 2, 3].map((n, index) => {
                        if (index < 4) {
                            return (
                                <EventsCard 
                                    key={index} // Ensures unique keys for React list rendering
                                    startIndex={startIndex}
                                    event={events[Math.abs(startIndex + index) % events.length] || events} 
                                    index={index}
                                />
                            );
                        }
                        return null;
                    })}

                    {/* Navigation Buttons */}
                    </div>
                <div className='features-div mb-80'>
                    <Button 
                        onClick={() => setStartIndex(startIndex - 1)} 
                        className="events-slider-arrow-btn events-left-arrow"
                        aria-label="Previous events" // Improves accessibility
                    >
                        <ChevronLeft size={25} />
                    </Button>
                    <Button 
                        onClick={() => setStartIndex(startIndex + 1)} 
                        className="events-slider-arrow-btn events-right-arrow"
                        aria-label="Next events" // Improves accessibility
                    >
                        <ChevronRight size={25} />
                    </Button>
                </div>
            </div>
    )
}