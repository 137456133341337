import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { splitParagraphs } from '../../tools/main';

export default function SingleEvent() {
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [eventKey, setEventKey] = useState(null)
    const [event, setEvent] = useState(null)



    useEffect(()=> {
        const pathname = window.location.pathname;
        // console.log(pathname, parts)
        const parts = pathname.split("/")
        const key = parts[parts.length - 1]
        // console.log(pathname, parts,key)

        // console.log(key)
        setEventKey(key)
    },[])
    useEffect(()=>{
        if (eventKey != null) {
            // console.log(eventKey)
            async function getEvent(eventKey) {
                const response = await axios.get("/event/find/"+eventKey).then(res=>{
                   console.log(res.data) 
                    // if event has been found
                    if (res.data.success == true) { 
                        return res.data.event
                    } else {
                        //return to events page
                    }
                }).catch(err=>{
                    return err
                })

                return response
            }
    
            const response = getEvent(eventKey).then((result)=>{
                if (result != null) {
                    didMount(true)
                    setEvent(result)
                    
                } else {
                    console.log(result)
                    //navigate("/events")
                }

                
            }).catch(err=>{
                console.log(err)
            })

            
        }

        
    }, [eventKey])

    useEffect(()=>{
        if (isMounted) {
            isLoading(false)
        }
    }, [isMounted])

    if (!loading) return (
        <Container className='mt-150'>
            <Row className='mb-50 row-min-h800 max-width-1' style={{ margin: "auto"}} >
                
                <Col xs={10} sm={5} className='mx-auto pb-2' style={{height: "100%", minHeight: "250px"}}>
                <div>
                    <Image 
                        style={{border: "1px solid rgba(0, 0, 0, 0.175)", borderRadius: '0.375rem', width: "100%", height: "100%"}}
                        src= { window.location.origin + '/event/images/' + event.poster_url } />
                </div>

                </Col>
                <Col xs={10} sm={7} className='mx-auto'>
                    <Card className='single-event-card'>
                        <Card.Body>
                            <h2>  { event.title }</h2>
                            <h6> Time: {event.date + ', ' + event.time + ' ' + event.timezone}  </h6>
                            
                        <Card.Text>
                            { splitParagraphs(event.description).map((para)=> {
                                if (para != '') return (
                                   <>
                                     
                                     <p> {para}<br/> </p>
                                   
                                   </> 
                                )
                            }) }
                            <br/>
                            <br/>
                                <small > 
                                <span style={{ fontWeight: "bold"}}> Location: </span> {event.location} </small>
                            
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                
            </Row>

        </Container>
        )
    

}