import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, Modal, Image, ListGroup, Carousel, Alert } from 'react-bootstrap';
import { Calendar, Lightbulb, People, Compass, Plus, Newspaper, CalendarWeek, BoxArrowInUpRight, Globe2, Clock } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import bg_img from '../../images/csam-bg.jpeg'


import { Swiper, SwiperSlide } from "swiper/react";
import KeenSlider from 'keen-slider'

import EmbedSlack from '../widgets/slack/slack.widget.component';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";


// import required modules
import { Autoplay, Pagination, Navigation, EffectCoverflow } from "swiper";
import { height } from '@mui/system';

import "../main-style.css"
import axios from 'axios';
import CsamCalendar from '../calendar/csam-calendar';
import HeroComponent from './hero.component';
import Stories from './stories';
import { CsamObjectives } from './csamobjectives';
import { ShowEvents } from './showevents';


export default function Home() {
    const navigate = useNavigate()
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [events, setEvents] = useState([...Array(10).keys()])
    const [latest, setLatest ] = useState([])
    
    const [errMessage, setErrMsg] = useState('')
    
    const getEvents = async ()=>{
        const response = await axios.post("/event/all").then(res => {
            console.log(res)
            if (res.data.success) {
                return res.data.events
            } else {
                throw new Error('Not successful in retrieving data.')
            }
        }).catch(error =>{
            return error
        })
        return response
    }

    useEffect(()=> {
        
        async function fetchData(){
            const response = await getEvents().then(result=> {
                console.log(result)
                setEvents(result)
                didMount(true)
            }).catch(error=>{
                setErrMsg('Can not retrieve CSAM events at the moment. Please try again later.')
                console.log(error)
                didMount(true)
            })
        }

        fetchData();

        
    }, [])

    useEffect(()=> {
        if (isMounted) isLoading(false)
    },[isMounted])

    return (
        <Container id="home-container">
            {/* Home Banner */}
            <HeroComponent />

            <div id="home-body">

                    <section>
                        <Stories />
                    </section>

                    <section>
                       <ShowEvents events = {events} /> 
                    </section>

           </div>
        </Container>

    )
}


