import React, { useEffect, useState, useContext } from 'react';
import { Container, DropdownButton, ButtonGroup, Row, Col, Card, Button, Nav, NavDropdown, Modal, Image, ListGroup, Navbar, Form, FormGroup, Alert, Dropdown, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import axios from 'axios';
import { Check2Circle } from 'react-bootstrap-icons';
import { displayText, splitParagraphs, strToDateObject } from '../../tools/main';
import EventForm from './eventform';


function OpportunityCard ({item, handleDelete, auth}) {
  const [collapse, handleCollapse] = useState(false)
  const navigate = useNavigate()
  
  useEffect(()=> {
    console.log(collapse)
  }, [collapse])

  // if opp target is members only
  return (
      <Col xs={12} sm={6} md={4} xxl={3} className='opp-card-col'>
        <Card 
          className='opp-card' 
            onClick={() => navigate('/opp', { state: { data: item } })}
          >
          <Card.Body >
            <div>
              <p style={{ fontStyle: "italic", fontSize: "small", marginBottom: "2px"}}>
                {item.category == 'Research'? ("Research&Funding"):(item.category)} 
                <span className= {item.target_audience == 'members' && auth.membershipstatus == 'Active'? ('show'):('hide')} style={{ display: "inline-block"}}>
                &#183;
                  <Badge  bg="secondary">
                    Member's only 
                  </Badge> 
                </span>
              <span style={{ float: "right"}}> {strToDateObject(item.date_created).toDateString()} </span>

              </p>
            </div>
            <Card.Title className='opp-title'> { item.title } </Card.Title>
            <Card.Text  className={ !collapse? 'opp-text': 'opp-text show-more '} >
              <div>
              { splitParagraphs(item.description).map(para => {
                return (
                  <> {para} <br/> </>
                )
              }) }
              </div>
              
            </Card.Text>
            
            <div style={{ position: "absolute", bottom:"10px", width: "100%", paddingRight: "25px" }}>
              {/* <Button
              variant='outline-primary'
              style={{ }}
              onClick={e => handleCollapse(!collapse)}> 
                {!collapse? 'Read more':'Show less'}
              </Button>  */}
              { auth.accesslevel == 9  &&(<Button 
                variant='outline-danger'
                id = {item.opp_id}
                onClick={e=> handleDelete(e)}
                style={{ float: "inline-end", width: "100px"}}
                > Delete 
                </Button>) }
            </div>

          </Card.Body>
        </Card>
      </Col>
  )
}
export default function OpportunitiesPage() {
    const { auth } = useContext(AuthContext);
    const [categories, setCategories] = useState([]);
    const [opportunites, setOpportunities] = useState([])
    // const [listing, showListing] = useState([])
    const [filter, setFilter] = useState("")
    const [mounted, isMounted] = useState(false)
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)
    const [showEventForm, setShowEventForm] = useState(false)
    const [formComplete, setFormComplete] = useState(false)
    const [newForm, setNewForm] = useState(0)
    const [submissionLoading, loadSubmission] = useState(false)

    useEffect(() => {
        async function fetchData() {
          return await axios.get('/opportunities/all')
            .then(res=> {
              console.log(res)
              return res.data
            }).catch(err => {
              throw Error ('Can not fetch data')
            })

        }

        fetchData().then(res=>{
          console.log(res)
          if (!res.success) {
            throw Error ('Erro!')
          } else {
            setOpportunities(res.opportunities.reverse())
          }
        }).catch(err=> {
          console.log(err)
        });

        isMounted(true)

    }, [count]);

    useEffect(()=> {
      if (mounted) setLoading(false)
    },[mounted])
    useEffect(()=> {
      console.log(opportunites)
    },[opportunites])
    useEffect(()=>{
      setFormComplete(false)
      setShowEventForm(false)
    },[newForm])


    useEffect(()=> {
      if (formComplete == true) {
        setTimeout(()=>{
          setNewForm(newForm+1)
          setCount(count+1)
        },2000)
      }
    }, [formComplete])

    const handleDelete = async (e) => {
      e.preventDefault()
      const key = e.target.id

      await axios.delete("/opportunities/delete/"+key)
        .then(res=> {
          if (res.data.success) {
            setCount(count+1)
          }
        }).catch(err => {
          console.log(err)
        })
    }
    const handleFormSubmit = async (data) => {
        loadSubmission(true)
        // Handle the form submission here, for example, send the data to a server or update state.
        data['author'] = auth.email
        //1. send data to back end
        const response = await axios.post('/opportunities/new', data)
          .then((result)=>{
            if (result.data.success) {
              // reload component to update list and clear data
              setFormComplete(true)
              // clear previous inputs
              return true
            } else {
              throw Error('Error!')
            }
          }).catch((err)=>{
            console.log(err)
            return false
          })
        
        return response
      };

    if (!loading) {
        return (
            <>
            
              <Row className="mt-100">
                <Col className='mx-auto mw-1200'>
                    <div>
                      { auth.accesslevel == 9? (
                      
                      <Button 
                        style={{ marginRight: "10px"}}
                        disabled = {formComplete ? true: false} 
                        variant = 'outline-primary' 
                        onClick = {(e)=>{ !formComplete && setShowEventForm(!showEventForm) } }  
                        >New Opportunity</Button>):(<></>)}
                      
                      <DropdownButton 
                        as={ButtonGroup} 
                        title={'Filter'} 
                        style={{ color: "black", paddingRight: "10px", minWidth: "100px"}}

                        variant={"outline-primary"}
                        key = "1"
                        onSelect={(event)=> {setFilter(event)}}
                      >
                        <Dropdown.Item eventKey={""}>All</Dropdown.Item>
                        <Dropdown.Item eventKey={"General"}>General</Dropdown.Item>
                        <Dropdown.Item eventKey={"Conference"}>Conference</Dropdown.Item>
                        <Dropdown.Item eventKey={"Volunteer"}>Volunteer</Dropdown.Item>
                        <Dropdown.Item eventKey={"Research"}>Research & Funding </Dropdown.Item>
                        <Dropdown.Item eventKey={"Awards&Scholarships"}>Awards & Scholarships</Dropdown.Item>
                        <Dropdown.Item eventKey={"Employment"}>Employment</Dropdown.Item>
                      </DropdownButton>
                    </div>

                    <div>
                        <Card className={showEventForm? "show mt-10":"hide"}>
                            
                              {formComplete == false? (
                                <Card.Body> 
                                  <EventForm onSubmit={handleFormSubmit} /> 
                                </Card.Body>):(
                                <Alert variant='success' style={{ height: "inherit", margin: "0px"}}>
                                  Succussfully submited. <Check2Circle />
                                </Alert>)
                              }
                        </Card>
                    </div>
                      
                    <Row className='mt-10'>
                      { opportunites.map((item, index) => {
                        
                        console.log('here')
                        if (item.target_audience != 'members') {
                          if (filter == item.category) {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          } else if (filter == "") {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          }
                        } else {
                          if (filter == item.category && auth.membershipstatus == 'Active') {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          } else if (filter == "" && auth.membershipstatus == 'Active') {
                            return (<OpportunityCard item={item} handleDelete={handleDelete} auth = {auth} />)
                          }
                        }


                      })}
                    </Row>
                </Col>
            </Row>

          </>

        );
    }

    return null;
}



