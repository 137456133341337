import { Card } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";

const newsData = [
    {
        title: "CStaRS Members Shine at the 2024 Canadian Space Health Research Symposium",
        text: "At the 2024 Canadian Space Health Research Symposium, Harmil K. presented research on how soil granularity, compaction, and nutrients impact plant fertilization using lunar and Icelandic soil analogues.",
        type: 'article',
        src: 'https://static.wixstatic.com/media/5dd736_139400c56b184bb28538f6786b9cae9e~mv2.png/v1/crop/x_0,y_0,w_1080,h_663/fill/w_1078,h_663,al_c,q_90,enc_avif,quality_auto/INSTAGRAM%20CSHRNet%20Symposium%202024%20Promo.png',
        source_url: 'https://www.linkedin.com/company/canadian-society-for-aerospace-medicine-student-resident-section-cstars/posts/?feedView=all',
        date_created: "June 15, 2024",
        category: "Research"
    },
    {
        title: "Cool job: Dr. Joelle Thorgrimson, FP and flight surgeon, Royal Canadian Air Force",
        text: "Thinking you might want to try something new? In this 'Cool Jobs' series, we highlight some inspiring Canadian physicians who are doing something a little different",
        type: "article",
        src: 'https://assets1.canadianhealthcarenetwork.ca/styles/max_width_480/s3/2023-10/cool_job_thorgrimson.jpg?itok=G5tAH47V',
        source_url: 'https://www.canadianhealthcarenetwork.ca/cool-job-dr-joelle-thorgrimson-fp-and-flight-surgeon-royal-canadian-air-force',
        date_created: "October 23, 2023",
        category: "Career Spotlight"
    },
    {
        title: "Zero Gravity, Zero Gain: The Genetics Behind Muscle Loss in Space",
        text: "Simulated microgravity in mice reveals a constellation of genes that may influence muscle weakening during spaceflight.",
        type: "article",
        src: "https://cdn.the-scientist.com/assets/articleNo/71612/hImg/52033/65248-ts-istock-resize-spaceman-1800x720-x.webp",
        source_url: 'https://www.the-scientist.com/news/zero-gravity-zero-gain-the-genetics-behind-muscle-loss-in-space-71612',
        date_created: "May 28, 2024",
        category: "Science"
    },
    {
        title: "Dr. Adam Sirek on the Starliner's Stranded Astronauts",
        text: "Stranded NASA astronauts Barry Wilmore and Sunita Williams speak to the media for the first time.",
        type: "media",
        src: "https://www.youtube.com/embed/DpKkdIIaBsE",
        date_created: "June 28, 2024",
        category: "Media"
    }
];

function NewsCard({ item }) {
    return (
        <Card className="nasa-style-card">
            <div className="card-image-container">
                {item.type === "media" ? (
                    <iframe 
                        src={item.src}
                        width="100%" 
                        height="200"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={item.title}
                    ></iframe>
                ) : (
                    <Card.Img variant="top" src={item.src} alt={item.title} />
                )}
                {item.category && (
                    <div className="card-category">{item.category}</div>
                )}
            </div>
            <Card.Body>
                <Card.Text className="card-date">{item.date_created}</Card.Text>
                <Card.Title>{item.title}</Card.Title>
                <Card.Text className="card-excerpt">{item.text}</Card.Text>
                {item.source_url && (
                    <a href={item.source_url} target="_blank" rel="noopener noreferrer" className="read-more-link">
                        Read More <ArrowRight size={14} />
                    </a>
                )}
            </Card.Body>
        </Card>
    );
}

export default function FeaturedNews() {
    return (
        <div className="nasa-featured-news">
            <div className="section-header">
                <h2>Featured Stories</h2>
                <a href="#" className="view-all-link">View All News</a>
            </div>
            
            <div className="news-grid">
                {newsData.map((item, index) => (
                    <NewsCard key={index} item={item} />
                ))}
            </div>
        </div>
    );
}