import React, { useEffect, useState, useContext } from 'react';
import { Container, DropdownButton, ButtonGroup, Row, Col, Card, Button, Nav, NavDropdown, Modal, Image, ListGroup, Navbar, Form, FormGroup, Alert, Dropdown, Badge } from 'react-bootstrap';


export default function EventForm ({ onSubmit }) {
    const [loading, isLoading] = useState(false)
    const [item, setItem] = useState({
        title: '',
        description: '',
        category: '',
        author: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setItem((prevItem) => ({ ...prevItem, [name]: value }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();

        const success = await onSubmit(item);
        if (success) {
          setItem({
            title: '',
            description: '',
            category: 'General',
            target_audience: '',
            author: ''
          })
        } else {
          console.log(success, 'Unable to submit Form')
        }
        // You can add additional logic here, such as clearing the form or navigating to another page.
      };
    return (
      <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formTitle" style={{ paddingBottom: "0.6rem"}}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              name="title"
              value={item.title}
              onChange={handleChange}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group style={{ paddingBottom: "0.6rem"}}>
                <Form.Select 
                  aria-label='Category' 
                  className='mt-2' 
                  name="category"
                  onChange={handleChange}>
                  <option > Category </option>
                  <option value ='General'>General</option>
                  <option value ='Volunteer'>Volunteer</option>
                  <option value ='Conference'>Conference</option>
                  <option value ='Research'>Research Opportunities & Funding </option>
                  <option value ='Awards&Scholarships'>Awards & Scholarships</option>
                  <option value ='Employment'>Employment</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group style={{ paddingBottom: "0.6rem"}}>
                  <Form.Select 
                    aria-label='TargetAudience' 
                    className='mt-2' 
                    name="target_audience"
                    onChange={handleChange}>
                    <option > Target Audience </option>
                    <option value ='public'>Public</option>
                    <option value ='members'>Members only</option>
                    <option value ='cstars'>CStaRS Members</option>
                  </Form.Select>
              </Form.Group>
            </Col>
          </Row>


          <Form.Group controlId="formText" style={{ paddingBottom: "0.6rem"}}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              placeholder="Enter description (include relevant urls, links, etc.)"
              name="description"
              value={item.description}
              onChange={handleChange}
            />
          </Form.Group>
          <Button 
            variant="primary" 
            type="submit" 
            className='mt-2' 
            >
            Submit
          </Button>
        </Form>
      );
}