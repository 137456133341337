import React, { useContext, useEffect, useState } from 'react';
import { Navbar, NavDropdown, Container, Nav, Breadcrumb, BreadcrumbItem, Button, Dropdown, DropdownButton, Stack } from 'react-bootstrap';
import { DoorOpenFill, GearFill, Instagram, Linkedin, PersonCircle, PersonFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import logo from '../../images/csam-logo2.png'
import '../main-style.css'
import { createGuestUser, getURLParams, newSession } from '../../tools/main';



export default function NavMenu () {
    const navigate = useNavigate()
    const { auth, setAuth, session, setSession } = useContext(AuthContext)
    const [signedOut, didSignOut] = useState(false)
    const [loading, isLoading] = useState(false)
    const [isMounted, didMount] = useState(false)

    const [currentURL, setCurrentURL] = useState(null)

    const urlParams = new URLSearchParams(window.location.href)

    //console.log(urlParams)
    useEffect(()=> {
        
        didMount(true)
        
        const currentURLArray = getURLParams()
        const temp_currURL = currentURLArray[currentURLArray.length-1]
        
        if (temp_currURL == '') {
            setCurrentURL('home')
        } else {
           // console.log(currentURLArray)
            setCurrentURL(temp_currURL)
        }


    }, [])


    useEffect(()=> {
        // console.log(auth)
        if (isMounted) { 
            isLoading(false)

        }
    }, [isMounted])

    useEffect(()=> {
        if (signedOut) {
            navigate("/login")
        }
        
    },[signedOut])

    useEffect(()=> {
        // if the current url is part of the navigation menu, indicate that it is active
        if (!loading && currentURL != null)  {
            if (["home-nav", "about-nav", "membership-nav", "event-nav", "cstars"].includes(currentURL)) {
                const active_tab = currentURL + '-nav'
                const el = document?.getElementById(active_tab)
                el?.classList.add("active-menu")
            }
        }
    }, [loading, currentURL])
    useEffect(()=> {
        if (!isMounted) {
            // console.log(currentURL)
        }
    }, [isMounted, currentURL])

    const signOut = async (e) => {
        e.preventDefault()

        //Clear auth and sessions
        let newAuth = await createGuestUser()
        let newSess = await newSession()
        setAuth(newAuth)
        setSession(newSess)


        //remove localStorage variables
        localStorage.clear()
        localStorage.setItem("loggedIn", false)


        //clear session storage
        sessionStorage.clear()
        
        didSignOut(true)
 
    }


    function OpportunitiesDropDown({ismember}) {

        return (
            <DropdownButton className='' id="opportunities-dropdown-basic-button" title="Opportunities" >
                { ismember != 'true' && <Dropdown.Item onClick={e=> navigate("/membership")}>Become a Member</Dropdown.Item>}
                <Dropdown.Item onClick={e=> navigate("/opportunities")}>Opportunities</Dropdown.Item>
                <Dropdown.Item onClick={e=> navigate("/cstars")}>CStaRS</Dropdown.Item>
                <Dropdown.Item onClick={e=> navigate("/FAQ")}>FAQ</Dropdown.Item>
                
                {/* <Dropdown.Item onClick={e=> navigate("/opportunities/conferences")}>Conferences</Dropdown.Item>
                <Dropdown.Item onClick={e=> navigate("/opportunities/awards")}>Awards & Scholarships</Dropdown.Item>
                <Dropdown.Item onClick={e=> navigate("/opportunities/research")}>Research Opportunities</Dropdown.Item>
                <Dropdown.Item onClick={e=> navigate("/opportunities/mentorship")}>Mentorship Programs</Dropdown.Item>
                <Dropdown.Item onClick={e=> navigate("/opportunities/faq")}>FAQ</Dropdown.Item> */}
            </DropdownButton>
        )
    }
    if (!loading) return (
        <>
        
        <Navbar className={window.location.pathname == '/' ? ("main-nav transparent"):("main-nav")} expand="lg">
            <Container fluid className="">
                <div className=' navbar-brand-div'>
                    <Navbar.Brand href="/" className= "fixed-top-menu" >
                        <img
                        alt=""
                        src={logo}
                        width="40"
                        height="40"
                        className="d-inline-block align-top"
                        style={{}}
                        />{' '}
                        <div style={{}}> 
                            CSAM 
                        </div> 
                    </Navbar.Brand>
                </div>


            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{}}/>
                
                <Navbar.Collapse  id="responsive-navbar-nav">
                   
                    <Nav className="navbar-nav-2" >
                        
                            <div className=' navx3' s>
                                <Nav.Link id="home-nav" className="nav-link-menu" href="/"> 
                                    Home 
                                </Nav.Link>
{/*                                 
                                <Nav.Link id="cstars-nav" className="nav-link-menu" href="/cstars"> 
                                    CStaRS 
                                </Nav.Link> */}
                                {/* <Nav.Link className="nav-link-menu" disabled href="/news"> News </Nav.Link> */}
                                <Nav.Link id="event-nav" className="nav-link-menu" href="/events"> 
                                    Events 
                                </Nav.Link>
                                <Nav.Link className="nav-link-menu" >
                                    <OpportunitiesDropDown ismember= {auth.ismember} />
                                </Nav.Link>

                                <Nav.Link id="about-nav" className="nav-link-menu" href="/about">
                                    About
                                </Nav.Link>
                            </div>
                            <div className=' navx3'>
                                <Nav.Link className='py-0'>
                                
                                { !auth.loggedIn ? ( 
                                            <div className='user-nav-div' style={{ }}>
                                                <Button variant="outline-warning" id={window.location.pathname == '/' ? "login-nav": ''} className="my-nav-link" onClick={e=> {navigate('/login')}}> Log In  </Button>
                                                <Button variant = "warning" id="register-nav" className="my-nav-link" onClick={e=> {navigate('/register')}}> Register</Button>
                                            </div>
                                        ):(
                                            <NavDropdown 
                                                title={ window.location.pathname == '/' ? <PersonCircle style={{ color: "white"}} /> : <PersonCircle  /> } 
                                                id="my-basic-nav-dropdown"  
                                                style={{ fontSize: "1.5rem", textAlign: "right"}}>
                                                <NavDropdown.Item onClick={e=>navigate("/myaccount")}>
                                                    <PersonFill /> My Account
                                                </NavDropdown.Item>
                                                {/* { auth.ismember != 'true' ? ():(<></>)} */}
                                                { auth.accesslevel == 9 ? (
                                                        <>
                                                            {/* <NavDropdown.Divider /> */}
                                                            <NavDropdown.Item onClick={e=>navigate("/admin")}>
                                                                <GearFill /> Admin Portal
                                                            </NavDropdown.Item>
                                                        
                                                        </>):(<></>)
                                                    }
                                                <NavDropdown.Divider />

                                                <NavDropdown.Item onClick={e=> {signOut(e)}} >
                                                    <DoorOpenFill /> Log Out
                                                </NavDropdown.Item>
                                            

                                            </NavDropdown>
                                            
                                        ) } 
                                </Nav.Link>
                            </div>
                        
                            {/* <Nav.Link id="membership-nav" className="nav-link-menu" href="/membership">Members</Nav.Link> */}   
                    </Nav>
                </Navbar.Collapse>

                
                


            
            </Container>
      </Navbar>
      </>
    )
}