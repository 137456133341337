import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import { Card, Col, Container, Row, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { Key } from 'react-bootstrap-icons'
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from '../../../context/AuthProvider';
import { getIP, newSession, showPwd, signedInSession } from '../../../tools/main';

const VALIDATE_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const LOGIN_URL = '/user/login'

function DisplayLoginMessage () {
    const registrationType = sessionStorage.getItem('registrationType')
    return (
        <>
            <div className={ sessionStorage?.getItem('passwordChanged') == 'true'?('show'): ('hide') } >
                <Alert variant='success'>
                    Password change successful. Please login in with your new password.
                </Alert>
            </div>
            <div className={ sessionStorage.getItem('registrationType') == 'newUser'?('show'): ('hide') }>
                <Alert>
                    To ensure the security of your account, we kindly request that you 
                    verify your account upon your first login attempt. 
                    This step is essential for account activation and protecting your 
                    personal information.
                </Alert>
            </div>

            <div className={ sessionStorage.getItem('registrationType') == 'newUser-verified'?('show'): ('hide') }>
            <Alert variant='success'>
                Congratulations ! your account has been verified. Please sign in to access your account.
            </Alert>
            </div>
        </>

    )
}
export default function Login() {
    const {auth, setAuth, session, setSession} = useContext(AuthContext)
    const location = useLocation()
    const navigate = useNavigate()

    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const [successLogin, setSuccessLogin] = useState(false)
    const [accountVerified, setAccountVerified ] = useState(true)
    const [user, setUser] = useState('')
    const [email, setEmail] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [lockedLogin, lockLogin] = useState( localStorage.getItem("lockedLogin") == 'true'? (true):(false) )
    //state to remember log in attempts
    const [loginAttempts, setLoginAttempts] = useState(localStorage.getItem("lockedLogin") == 'true'? (5):(0))

    //track user login attempts

    const [loggedLoginAttempts, trackLoginAttempts] = useState(localStorage.getItem("lockedLogin") == 'true'? ({

    }):(null))
    //maximum login attempts before user is login attempts is suspended
    const maxLoginAttempts = 5

    //duration user will be locked in ms
    const loginLockedDuration = 0.5 * 60 * 1000

    useEffect(()=> {
        didMount(true)

    }, [])

    useEffect(()=> {
        isLoading(false)
    },[isMounted])


    useEffect(()=> {
        if (!loading) {
            const el = document.getElementById("login-btn")
            if (lockedLogin == true) {
                // console.log("login btn should be disabled")
                el.disabled = true

                trackLoginAttempts({
                    user,
                    lockedLogin: true,
                    lockedTime: parseInt(localStorage.getItem("lockedTime"))
                })
                //error message for user 
                setErrMsg("You have exceeded the maximum number of login attempts (4). Please click 'Forgot Password' to initiate a password reset or try again later.")
            } else {
                //console.log("login btn is enabled")
                if (errMsg == "You have exceeded the maximum number of login attempts (4). Please click 'Forgot Password' to initiate a password reset or try again later.") {
                    setErrMsg("")
                }

                el.disabled = false
            }
        }
    }, [lockedLogin, loading])
    useEffect(()=> {

        // console.log(localStorage.getItem("lockedLogin"))

        if (!loading) {
            //console.log("Login attempts:" , loginAttempts)
            if (lockedLogin) {

                //console.log(Date.now(),loggedLoginAttempts?.lockedTime )
                if (Date.now() >= loggedLoginAttempts?.lockedTime) {
                    //alert("you are free to login again")
                    //reset login attempts
                   
                    
                    setLoginAttempts(0)
                    
                    //update login logs
                    let userLoginAttemptData = {
                        user,
                        loginAttempts: 0,
                        lockedTime: null,
                    }

                    trackLoginAttempts(userLoginAttemptData)

                    localStorage.removeItem("lockedLogin")
                    localStorage.removeItem("lockedTime")
                    //unlock user's ability to login once suspension time has lapsed
                    lockLogin(false)
                } 
     
             } else if (loginAttempts >= maxLoginAttempts) {
                //set local storage locked login as true
                localStorage.setItem("lockedLogin", true)
                localStorage.setItem("lockedTime", (Date.now() + loginLockedDuration))
                lockLogin(true)

                trackLoginAttempts({
                    user,
                    loginAttempts,
                    lockedTime: Date.now() + loginLockedDuration,
                })

                //console.log(userLoginAttemptData)
    
        } else {
            // console.log("user did not max'd their login in attempts and is not locked", loginAttempts)
        } 
    }

    
    }, [loading, loginAttempts, loggedLoginAttempts])


    
    

    //sents post request to db to check if user credentials are valid
    const getUserCredentials = async (user, pwd) => {
        try {
            //remove blank spaces from email
            const userRemovedSpaced = user.replace(/\s+/g,"");
            //get user ip address
            const ip = await getIP()
            // console.log(userRemovedSpaced)
            const response = await axios.post(LOGIN_URL, {
                    userKey: "email",
                    user: userRemovedSpaced.toLowerCase(),
                    password: pwd,
                    ip,
                    date: Date()
                })
            // if user was found, return user info
            if (response.data.success) { 
                // console.log(response)   
                return response.data
            } else {
                const data = {

                }
                if (response.data.message == "Invalid password." ) {
                    return response.data
                } else if (response.data.message == "No account exists under this email.") {
                    return response.data
                } else {
                    throw new Error('Unexpected Error.')
                }   
                return false
            }
        } catch(err) {
            console.log(err)
            setErrMsg("Sorry! Our servers are currently down. We are currently trying fix this issue, please comeback at a later time.")
            return false
           }
    }

    // returns true if email is in db
    // async function checkIfEmailExists(userEmail) {
    //     try {
    //       const response = await axios.post('/user/check-email', { email: (userEmail.replace(/\s+/g,"")).toLowerCase()  });
    //       const { message, success } = response.data;
          
    //       if (message === 'Error In System.') {
    //             console.log(message)
    //             throw message
    //         }  else {
    //             //success is TRUE if user found, FALSE if not found
    //             return success
    //       }
    //     } catch (error) {
    //         throw new Error("Sorry! It appears that there is some internal maintenance going on. Please try logging in again later.")
    //     }
    //   }

    const authenticateUser = async (user, pwd) => {
        try {
            await getUserCredentials(user, pwd).then( async (result) => {
                //console.log(result)
                // if result == false, user password was invalid
                console.log(result)
                 if (result.success != false){
                    
                    //throw error if user exists, but can not retrieve data
                    if (Object.keys(result.user).length === 0) {
                        throw new Error('No user found')
                    }
                     if (!result.user.accountverified) {
                         // user account is not verified
                         // setErrMsg("Your account has not yet been verified. Please check your email (including junk/spam) for the verification link.")
                         sessionStorage.setItem( "email", user.toLowerCase() )
                         sessionStorage.setItem("registrationType", "newUser")
                         navigate("/verify-account")
                         //send verification link
                         // console.log("User account has not been verified")
                     } else {
                         //check if user needs to change PWD
                         if (result.user.renewPD != true ) {
                             // is user does not need to renew their pwd
                             
                             // updating authentication
                             let newAuth = {...result.user}
                             newAuth.loggedIn = true
                             newAuth.accessToken = result.accessToken

                             //send access and refress token to localStorage, then api will get user
                             localStorage.setItem("accessToken", result.accessToken)
                             localStorage.setItem("refreshToken", result.refreshToken)
                             localStorage.setItem("loggedIn", true)
                            
                             setAuth(newAuth)

                             //create logged in session
                             let newSess = await signedInSession()
                             newSession.accessToken = result.accessToken
                             newSession.refreshToken = result.refreshToken

                             setSession(newSess)

                             //success
                             sessionStorage.removeItem("registrationType")   
                             setSuccessLogin(true)
                            
                            navigate('/myaccount', {replace: true} )
                         } else {
                             // user must renew password
                             // console.log(result.data.user)
                             navigate('/change-password/' + result.user.userid)
                         }
                     }
                     return true
                 } else {
                    // user pwd was not valid, but email was
                    if (result.message != 'No account exists under this email.')  {
                        setLoginAttempts(loginAttempts + 1)
                    }
                    setErrMsg(result.message)
                    
                 }
            })

        } catch(error) {
            console.log(error)
            setErrMsg("Sorry! It appears that there is some internal maintenance going on. Please try logging in again later.")
        }
    }

    useEffect(()=> {
        if (successLogin) {
            localStorage.setItem("loggedIn", true)
            // console.log("successful login.")
        }
    },[successLogin])

    // handles forgot pwd ressets
    const handleForgotPwd = (e) => {
        e.preventDefault()
        sessionStorage.setItem("email", user)
        sessionStorage.setItem("registrationType", "Password change")
        navigate("/verify-account")
    }

    const onSubmit = async (event) => {
        event.preventDefault()
        if (user != "" && pwd != "" ) {
            try {
                await authenticateUser(user, pwd)
            } catch(err) {
                console.log(err)
            }   
        } else {
            setErrMsg("Please fill required fields.")
        }
    
    }

    const loginView = () => {

        return(
            <div>
                < DisplayLoginMessage />
                <Card className='mt-2'>
                    <Card.Header> Log In </Card.Header>
                    <Card.Body>
                        <Form onSubmit={onSubmit}>
                            <Form.Group className='mt-3'>
                                {/* <Form.Label> Email </Form.Label> */}
                                <Form.Control 
                                type="text"
                                value={user}
                                onChange={e=>setUser(e.target.value)}
                                placeholder="Email"/>
                                
                            </Form.Group>

                            <Form.Group className='mt-3'>
                                {/* <Form.Label> Password </Form.Label> */}
                                <Form.Control 
                                id='password'
                                type="password"
                                value={pwd}
                                onChange={e=>setPwd(e.target.value)}
                                placeholder="Password"/>
                                
                            </Form.Group>

                            <InputGroup className="mt-2">
                                <Form.Check 
                                    type= "checkbox"
                                    id= "pwd-show"
                                    label= "Show password"
                                    onClick={ e => showPwd (['password'])}
                                />
                            </InputGroup>

                            <small>
                                Don't have an account? <a href="/register">Register</a>
                            </small>
                            
                            <br/>
                            <div style={{ textAlign: "center"}}>
                                <Button 
                                    id="login-btn" 
                                    variant="warning" 
                                    className='mt-2 btn-reg-form' 
                                    type="submit">
                                    Log In
                                </Button>
                                <br/>
                                {
                                    loginAttempts > 0 || lockedLogin ? (
                                        <Button 
                                            variant="outline-warning" 
                                            className='mt-2 btn-reg-form-reverse-font' 
                                            onClick={e=> { handleForgotPwd(e)}}>
                                            Forgot password?
                                        </Button>
                                    ):(<></>)
                                }
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

        )
    }


    if (!loading) {
            return (
            <Container className='mt-100'>
                <Row className='mt-5 mb-5'>
                    <Col></Col>
                    <Col xs={10} md={6}>
                        <Alert variant='danger' className={ errMsg == ''? ("hide"):("show")} >
                            { errMsg }
                        </Alert>

                        { 
                            loginView() 
                        }

                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            )
    }
}