import { useEffect } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

// If using state:
import { useLocation } from 'react-router-dom';
import { splitParagraphs, strToDateObject } from "../../tools/main";
import React from "react";



export default function OpportunityPage() {
    const navigate = useNavigate()
    const location = useLocation();
    const data = location.state?.data;
    
    useEffect(()=> {
        console.log(data)
    }, [data])

    return (
        <Container>
            <Row className="mt-100 ">
                <Col className="mx-auto mw-1200">
                    <Card>
                        <Card.Body>
                            <Card.Title className="mb-4"> {data.title}</Card.Title>
                            <Card.Subtitle className="mb-2"> Category: {data.category} </Card.Subtitle>
                            <Card.Text>
                                { splitParagraphs(data.description).map(para => {
                                    return (
                                    <> {para} <br/> </>
                                    )
                                }) }

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer >
                            <small style={{float: "right"}}>
                                { strToDateObject(data.date_created).toDateString() }
                            </small>

                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}