import React, { useContext, useState, useEffect } from 'react';
import { Card, Col, Container, Row, Image, ListGroup } from 'react-bootstrap';
import AuthContext from '../../../../context/AuthProvider';
import { Gear, GearFill, GraphUpArrow, House, People, Person, PersonAdd, PersonBadge, PersonCircle } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';




export function AdminMenu({currentView, setView}) {
    const {auth, setAuth} = useContext(AuthContext)
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)
    const navigate = useNavigate()
    
    useEffect(()=> {
        didMount(true)
    }, [])
    useEffect(()=> {
        if (isMounted) isLoading(false)
    }, [auth, isMounted])

    if (!loading) return (
        <div className='px-0' style={{ }} >
            <ListGroup className='admin-menu'>
                <ListGroup.Item style={{ cursor: "default", fontWeight: "bold"}}> 
                    Account
                </ListGroup.Item>
                <ListGroup.Item>
                    User: { auth.displayname }
                </ListGroup.Item>
                <ListGroup.Item onClick={e=> navigate('/myaccount')}> 
                    <Person  /> My profile
                </ListGroup.Item>
                <ListGroup.Item onClick={e=> navigate('/')}>
                    <House /> Home
                </ListGroup.Item>

                <ListGroup.Item style={{ cursor: "default", fontWeight: "bold"}}>
                    Administration
                </ListGroup.Item>
                <ListGroup.Item onClick={e=> setView('manage-members')}>
                    <People /> Manage users
                </ListGroup.Item>
                <ListGroup.Item onClick={e=> setView('analytics')}>

                    <GraphUpArrow /> Analytics
                </ListGroup.Item>
                <ListGroup.Item  onClick={e=> setView('settings&permissions')}>
                    <Gear /> Settings & permissions
                </ListGroup.Item>
                
                <ListGroup.Item></ListGroup.Item>
            </ListGroup>
        </div>
    )
}