import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../../context/AuthProvider"
import { Card, Col, Row } from "react-bootstrap"


export default function Analytics() {
    
    const {auth, setAuth} = useContext(AuthContext)
    const [isMounted, didMount] = useState(false)
    const [loading, isLoading] = useState(true)

    useEffect(()=> {
        didMount(true)


    }, [])
    useEffect(()=> {
        if (isMounted) isLoading(false)
    }, [auth, isMounted])

    if (!loading) return  (
        <Col id="analytics-board" className="mt-50">
            <Card>
                <Card.Body>
                <h5> Analytics </h5>
                <ul>
                    <li>Verified Accounts: </li>
                    <li> Total Active Members: </li>
                    <li> Total New Members in 2024 </li>
                    <li> Total New Members in 2025 </li>
                </ul>
                </Card.Body>
            </Card>
        </Col>

        )
}